import filter from 'lodash/fp/filter';
import flatten from 'lodash/fp/flatten';
import includes from 'lodash/fp/includes';
import isObject from 'lodash/fp/isObject';
import map from 'lodash/fp/map';
import some from 'lodash/fp/some';

import safeRound from './safeRound';

export const productsMap = {
  candle: 'Candle',
  candle_arcadia: ' Candle Arcadia',
  candle_corsica: ' Candle Corsica',
  candle_prelude: ' Candle Prelude',
  conditioner: 'Conditioner',
  curl_cream: 'Curl Cream',
  dry_shampoo: 'Dry Shampoo',
  mask: 'Mask',
  hair_mask: 'Hair Mask',
  hair_towel_wrap: 'Hair Towel Wrap',
  scalp_mask: 'Scalp Mask',
  shampoo: 'Shampoo',
  leavein: 'Hair Oil',
  leavein_conditioner: 'Leavein Conditioner',
  shimmer_hairoil: 'Shimmer Hair Oil',
  // Supplements Skeleton Type
  supplement_booster: 'Supplements Softgels',
  supplement_core: 'Supplements Capsules',

  // Supplements Skeleton Slug
  supplement_core_0001: 'supps caps #1',
  supplement_core_0002: 'supps caps #2',
  supplement_core_0003: 'supps caps #3',
  supplement_core_0004: 'supps caps #4',
  supplement_core_0006: 'supps caps #6',
  supplement_booster_hairgrowth: 'supps softgels',
  supplement_booster_hairgrowth_v2: 'supps softgels v2',

  // Supplements Refills Slug
  supplement_core_0001_pouch: 'supps caps refills #1',
  supplement_core_0002_pouch: 'supps caps refills #2',
  supplement_core_0003_pouch: 'supps caps refills #3',
  supplement_core_0004_pouch: 'supps caps refills #4',
  supplement_core_0006_pouch: 'supps caps refills #6',
  supplement_booster_hairgrowth_pouch: 'supps softgels refills',
  supplement_booster_hairgrowth_v2_pouch: 'supps softgels refills v2',

  // Dry Shampoo BIG Slug
  // TODO: Replace by data driven name
  dry_shampoo_normal_big: 'DRY SHAMPOO BIG',

  // Trials
  trial_conditioner: 'Trial Conditioner',
  trial_shampoo: 'Trial Shampoo',

  // Brushes
  brush_boar: 'Boar Bristle',
  brush_agave: 'Agave Bristle',
  brush_mixed: 'Boar & Nylon Bristle',
  brush_detangling: 'Tipped-Metal Bristle',

  styling_gel: 'Styling Gel',
  cleanser: 'Cleanser',
  moisturizer: 'Moisturizer',
  serum: 'Serum',

  scalp_massager_gwp_v2: 'Purple Scalp Massager Gwp',
  scalp_massager_gwp_v1: 'Scalp Massager Gwp',
};

export const colorTipMap = {
  brush_boar: '#c27ba0',
  brush_agave: '#92c47c',
  brush_mixed: '#6d9eeb',
  brush_detangling: '#ffd965',

  shampoo: '#ffbb9b',
  conditioner: '#c5c2d2',
  mask: '#bdac9c',
  hair_mask: '#bdac9c',
  scalp_mask: '#f0b682',
  hairoil: '#b3c0b6',
  shimmer_hairoil: '#9ff1d6',
  leavein_conditioner: '#3572A5',
  curl_cream: '#89e051',
  dry_shampoo: '#c384bb',
  styling_gel: '#adc8d5',
  scalp_serum: '#85c3cc',

  // Pumps
  pump__curl_cream: '#fd0',
  pump__leavein_conditioner_light: 'green',
  pump__leavein_conditioner_rich: 'red',
  pump__shampoo_conditioner: 'blue',
  pump__pump_150_ml: '#fd0',
  pump__pump_150_ml_pkgr: '#fd0',
  pump__leavein_conditioner_rich_pkgr: 'red',
  pump__shampoo_conditioner_pkgr: 'blue',

  // Supplements
  supplement_booster: '#F9CF77',
  supplement_core: '#F9CF77',

  // Skincare
  cleanser: '#E2F5B3',
  moisturizer: '#8CDEA8',
  serum: '#80CCC7',
  cleanser_mini: '#E2F5B3',
  moisturizer_mini: '#8CDEA8',
  serum_mini: '#80CCC7',

  // Collaterals
  letter_core: '#FA74DC',
  letter_skincare: '#FA74DC',
};

const ingredientDensityMap = {
  bronze_powder: 3.16105263157895,
  gold_powder: 3.16421368421053,
};

const convertMlToGrForIngredient = ingredient => ({
  ...ingredient,
  quantity: ingredient.quantity * ingredientDensityMap[ingredient.name],
  quantityUnit: 'gr', // ingredient came as mL from payload but has to be in gr, forcing it
  precision: 3, // powders precision with 3 digits vs 2 for others
});

export const getIngredientsOrdered = (ingredients, skeleton) => {
  // Oil customer ingredients order
  if (skeleton.type === 'leavein') {
    return flatten([
      filter(
        i => safeRound(i.quantity, 2) <= 1 && i.name !== 'ESSENTIAL OIL' && i.name !== 'VITAMIN E',
        ingredients
      ),
      filter(i => i.name === 'ESSENTIAL OIL', ingredients),
      filter(i => i.name === 'VITAMIN E', ingredients),
      filter(i => safeRound(i.quantity, 2) > 1, ingredients),
    ]);
  }
  // Shimmer Hair Oil customer ingredients order
  if (skeleton.type === 'shimmer_hairoil') {
    return flatten([
      filter(
        i =>
          !includes(i.name, ['essential_oil', 'vitamin_e', 'bronze_powder', 'gold_powder']) &&
          safeRound(i.quantity, 2) <= 2,
        ingredients
      ),
      filter(i => i.name === 'ESSENTIAL OIL', ingredients),
      filter(i => i.name === 'VITAMIN E', ingredients),
      filter(i => safeRound(i.quantity, 2) > 2 && i.name !== 'oil_fluidizer', ingredients), // The lead oil
      filter(i => i.name === 'oil_fluidizer'), // fluidizer is the only other ingredient in quantity > 2
      map(
        convertMlToGrForIngredient,
        filter(i => i.name === 'bronze_powder', ingredients)
      ),
      map(
        convertMlToGrForIngredient,
        filter(i => i.name === 'gold_powder', ingredients)
      ),
    ]);
  }

  // Any other skeletons than oils can pass through
  return ingredients;
};

/**
 * Maximum volume of actives in % that can be filled per skeleton
 * Some skeletons are agnostic and will return the value
 * Returns Float
 * Others would be dependant of containing silicone or not
 * Returns { silicone: Float, silicone_free: Float}
 */
const capping = {
  conditioner_coarse: 10,
  conditioner_coarse_v1: 10,
  conditioner_thin: 8,
  conditioner_thin_v3: 9,
  conditioner_thin_v1: 9,
  curl_cream_normal: 5,
  // Should be 7.85 but due to mismatch quantity announced we trick it
  // dry_shampoo_normal: 7.85,
  dry_shampoo_normal: 10.1,
  dry_shampoo_normal_big: 10.1,
  hair_mask_dry: 7.2,
  hair_mask_dry_v1: 7.2,
  hair_mask_oily: 7.28,
  hair_mask_oily_v2: 7.2,
  leavein_oil: 96,
  leavein_conditioner_light: 1.2,
  leavein_conditioner_rich: 1.25,
  scalp_mask_normal: 7.28,
  scalp_mask_normal_v1: 7.2,
  shampoo_dry: {
    silicone: 5.8,
    silicone_free: 4,
  },
  shampoo_normal: {
    silicone: 5.8,
    silicone_free: 4,
  },
  shampoo_normal_v1: {
    silicone: 5.8,
    silicone_free: 4,
  },
  shampoo_oily: 3,
  shampoo_oily_v1: 4,
  shimmer_hairoil: 85.7,
};

export const getHardCodedCapping = formula => {
  if (!formula.skeleton) {
    return null;
  }
  let cappingForFormula;
  const cappingPerSkeleton = capping[formula.skeleton.slug];

  if (isObject(cappingPerSkeleton)) {
    const formulaContainsSilicone = some(
      ingredient =>
        some(siliconeType => siliconeType === ingredient.name, ['silicone', 'silicone_v1']),
      formula.ingredients
    );
    cappingForFormula = formulaContainsSilicone
      ? cappingPerSkeleton.silicone
      : cappingPerSkeleton.silicone_free;
  } else {
    cappingForFormula = cappingPerSkeleton;
  }

  return safeRound((cappingForFormula / 100) * formula.skeleton.bottle_size, 2);
};
