import get from 'lodash/fp/get';
import isNil from 'lodash/fp/isNil';

import { createSelector } from 'reselect';
import type { RootState } from 'store';
import type { OmsSelector } from 'types/common';

import type { FeatureFlags, FeatureFlagsVariant, VariantOrBoolean } from './types';

type FeatureFlagState = RootState['featureFlags'];

const getFeatureFlagState: OmsSelector<FeatureFlagState> = state => state?.featureFlags;

// Get state.featureFlags.flags
const getFlags: OmsSelector<FeatureFlagState['flags']> = createSelector(
  getFeatureFlagState,
  get('flags')
);

// Get state.featureFlags.flagFetchStatus
const getFlagsFetchStatus: OmsSelector<FeatureFlagState['status']> = createSelector(
  getFeatureFlagState,
  get('status')
);
// Get state.featureFlags.flagFetchStatus === rejected || fulfilled
export const isFlagsDoneFetching: OmsSelector<boolean> = createSelector(
  getFlagsFetchStatus,
  flagFetchStatus => flagFetchStatus === 'rejected' || flagFetchStatus === 'fulfilled'
);

// Get state.featureFlags.flags.selfies-error-detection
const getSelfiesErrorDetectionFlag: OmsSelector<FeatureFlags['selfies-error-detection']> =
  createSelector(getFlags, get('selfies-error-detection'));
// Get state.featureFlags.flags.selfies-error-detection.active
const isSelfiesErrorDetectionFlagActive: OmsSelector<boolean> = createSelector(
  getSelfiesErrorDetectionFlag,
  get('active')
);
// Get state.featureFlags.flags.selfies-error-detection.variant
const getSelfiesErrorDetectionFlagVariant: OmsSelector<FeatureFlagsVariant> = createSelector(
  getSelfiesErrorDetectionFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForSelfiesErrorDetection: OmsSelector<VariantOrBoolean> = createSelector(
  isSelfiesErrorDetectionFlagActive,
  getSelfiesErrorDetectionFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowSelfiesErrorDetection: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForSelfiesErrorDetection,
  activeOrVariant => {
    if (typeof activeOrVariant === 'boolean') return activeOrVariant;
    return activeOrVariant === 'on';
  }
);
// Get state.featureFlags.flags.selfies
const getSelfiesFlag: OmsSelector<FeatureFlags['selfies']> = createSelector(
  getFlags,
  get('selfies')
);
// Get state.featureFlags.flags.selfies.active
const isSelfiesFlagActive: OmsSelector<boolean> = createSelector(getSelfiesFlag, get('active'));
// Get state.featureFlags.flags.selfies.variant
const getSelfiesFlagVariant: OmsSelector<FeatureFlagsVariant> = createSelector(
  getSelfiesFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForSelfies: OmsSelector<VariantOrBoolean> = createSelector(
  isSelfiesFlagActive,
  getSelfiesFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowSelfies: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForSelfies,
  activeOrVariant => {
    if (typeof activeOrVariant === 'boolean') return activeOrVariant;
    return activeOrVariant === 'on';
  }
);
// Get state.featureFlags.flags.microhold-palletization
const getMicroholdPalletizationFlag: OmsSelector<FeatureFlags['microhold-palletization']> =
  createSelector(getFlags, get('microhold-palletization'));
// Get state.featureFlags.flags.microhold-palletization.active
const isMicroholdPalletizationFlagActive: OmsSelector<boolean> = createSelector(
  getMicroholdPalletizationFlag,
  get('active')
);
// Get state.featureFlags.flags.microhold-palletization.variant
const getMicroholdPalletizationFlagVariant: OmsSelector<FeatureFlagsVariant> = createSelector(
  getMicroholdPalletizationFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForMicroholdPalletization: OmsSelector<VariantOrBoolean> = createSelector(
  isMicroholdPalletizationFlagActive,
  getMicroholdPalletizationFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);

export const shouldShowMicroholdPalletizationPhase5: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForMicroholdPalletization,
  activeOrVariant => activeOrVariant === 'phase-5'
);

export const shouldShowMicroholdPalletizationPhase: OmsSelector<boolean> =
  shouldShowMicroholdPalletizationPhase5;
// Get state.featureFlags.flags.survey-updates-phase-1
const getSurveyUpdatesPhase1Flag: OmsSelector<FeatureFlags['survey-updates-phase-1']> =
  createSelector(getFlags, get('survey-updates-phase-1'));
// Get state.featureFlags.flags.survey-updates-phase-1.active
const isSurveyUpdatesPhase1FlagActive: OmsSelector<boolean> = createSelector(
  getSurveyUpdatesPhase1Flag,
  get('active')
);
// Get state.featureFlags.flags.survey-updates-phase-1.variant
const getSurveyUpdatesPhase1FlagVariant: OmsSelector<FeatureFlagsVariant> = createSelector(
  getSurveyUpdatesPhase1Flag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForSurveyUpdatesPhase1: OmsSelector<VariantOrBoolean> = createSelector(
  isSurveyUpdatesPhase1FlagActive,
  getSurveyUpdatesPhase1FlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowSurveyUpdatesPhase1: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForSurveyUpdatesPhase1,
  activeOrVariant => {
    if (typeof activeOrVariant === 'boolean') return activeOrVariant;
    return activeOrVariant === 'on';
  }
);
// Get state.featureFlags.flags.data-driven-production-flows-front
const getDataDrivenProductionFlowsFrontFlag: OmsSelector<
  FeatureFlags['data-driven-production-flows-front']
> = createSelector(getFlags, get('data-driven-production-flows-front'));
// Get state.featureFlags.flags.data-driven-production-flows-front.active
const isDataDrivenProductionFlowsFrontFlagActive: OmsSelector<boolean> = createSelector(
  getDataDrivenProductionFlowsFrontFlag,
  get('active')
);
// Get state.featureFlags.flags.data-driven-production-flows-front.variant
const getDataDrivenProductionFlowsFrontFlagVariant: OmsSelector<FeatureFlagsVariant> =
  createSelector(getDataDrivenProductionFlowsFrontFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForDataDrivenProductionFlowsFront: OmsSelector<VariantOrBoolean> =
  createSelector(
    isDataDrivenProductionFlowsFrontFlagActive,
    getDataDrivenProductionFlowsFrontFlagVariant,
    (active, variant) => (active && !isNil(variant) ? variant : active)
  );
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowDataDrivenProductionFlowsFront: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForDataDrivenProductionFlowsFront,
  activeOrVariant => {
    if (typeof activeOrVariant === 'boolean') return activeOrVariant;
    return activeOrVariant === 'on';
  }
);
// Get state.featureFlags.flags.survey-updates-phase-2
const getSurveyUpdatesPhase2Flag: OmsSelector<FeatureFlags['survey-updates-phase-2']> =
  createSelector(getFlags, get('survey-updates-phase-2'));
// Get state.featureFlags.flags.survey-updates-phase-2.active
const isSurveyUpdatesPhase2FlagActive: OmsSelector<boolean> = createSelector(
  getSurveyUpdatesPhase2Flag,
  get('active')
);
// Get state.featureFlags.flags.survey-updates-phase-2.variant
const getSurveyUpdatesPhase2FlagVariant: OmsSelector<FeatureFlagsVariant> = createSelector(
  getSurveyUpdatesPhase2Flag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForSurveyUpdatesPhase2: OmsSelector<VariantOrBoolean> = createSelector(
  isSurveyUpdatesPhase2FlagActive,
  getSurveyUpdatesPhase2FlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowSurveyUpdatesPhase2: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForSurveyUpdatesPhase2,
  activeOrVariant => {
    if (typeof activeOrVariant === 'boolean') return activeOrVariant;
    return activeOrVariant === 'on';
  }
);
