import { createSlice } from '@reduxjs/toolkit';

import type { CustomerState } from './types/slice-types';
import {
  fetchCustomer,
  fetchCustomerCart,
  fetchCustomerCoupons,
  fetchCustomerFormulasPreview,
  fetchCustomerFragrances,
  fetchCustomerOrders,
  fetchCustomerProfiles,
  fetchCustomers,
  fetchCustomerSelfies,
  fetchCustomerSubscriptions,
  fetchCustomerSurveys,
  fetchErrorDetectionResult,
  fetchQuestionSets,
} from './thunks';

const initialState: CustomerState = {
  customers: [],
  currentCustomer: null,
  coupons: {
    credits: {
      coupons: {},
      couponDetails: {},
    },
    promos: {
      coupons: {},
      couponDetails: {},
    },
    creditsPages: {},
    promosPages: {},
  },
  fragrances: [],
  orders: [],
  subscriptions: [],
  surveys: [],
  cart: null,
  customerProfiles: { hairProfilePubkey: '', profiles: [] },
  formulasPreviewSkincare: null,
  formulasPreviewHaircare: null,
  hairProfileUpdatedAt: null,
  details: null,
  imageDocs: null, // dict of [customerPubkey]: { ...document, file, objectUrl }
  errorDetectionResults: null, // dict of [customerPubkey]: ErrorDetectionResult
  error: null,
  status: 'idle',
  pages: {},
  questionSet: null,
};

const customerSlice = createSlice({
  name: 'gifts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // Fetch customers
      .addCase(fetchCustomers.pending, draftState => {
        draftState.status = 'pending';
        draftState.customers = [];
        draftState.error = null;
      })
      .addCase(fetchCustomers.fulfilled, (draftState, action) => {
        const { customers, pages } = action.payload;

        draftState.status = 'fulfilled';
        draftState.customers = customers;
        draftState.pages = pages;
      })
      .addCase(fetchCustomers.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      // Fetch customer
      .addCase(fetchCustomer.pending, draftState => {
        draftState.status = 'pending';
        draftState.currentCustomer = null;
        draftState.error = null;
      })
      .addCase(fetchCustomer.fulfilled, (draftState, action) => {
        const customer = action.payload;

        draftState.status = 'fulfilled';
        draftState.currentCustomer = customer;
      })
      .addCase(fetchCustomer.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      // Fetch orders
      .addCase(fetchCustomerOrders.pending, draftState => {
        draftState.status = 'pending';
        draftState.orders = [];
        draftState.error = null;
      })
      .addCase(fetchCustomerOrders.fulfilled, (draftState, action) => {
        const { orders, pages } = action.payload;

        draftState.status = 'fulfilled';
        draftState.orders = orders;
        draftState.pages = pages;
      })
      .addCase(fetchCustomerOrders.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      // Fetch surveys
      .addCase(fetchCustomerSurveys.pending, draftState => {
        draftState.status = 'pending';
        draftState.surveys = [];
        draftState.error = null;
      })
      .addCase(fetchCustomerSurveys.fulfilled, (draftState, action) => {
        const { surveys, pages } = action.payload;

        draftState.status = 'fulfilled';
        draftState.surveys = surveys;
        draftState.pages = pages;
      })
      .addCase(fetchCustomerSurveys.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      // Fetch cart
      .addCase(fetchCustomerCart.pending, draftState => {
        draftState.status = 'pending';
        draftState.cart = null;
        draftState.error = null;
      })
      .addCase(fetchCustomerCart.fulfilled, (draftState, action) => {
        const cart = action.payload;

        draftState.status = 'fulfilled';
        draftState.cart = cart;
      })
      .addCase(fetchCustomerCart.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      // Fetch formulas preview
      .addCase(fetchCustomerFormulasPreview.pending, draftState => {
        draftState.status = 'pending';
        draftState.formulasPreviewSkincare = null;
        draftState.formulasPreviewHaircare = null;
        draftState.hairProfileUpdatedAt = null;
        draftState.error = null;
      })
      .addCase(fetchCustomerFormulasPreview.fulfilled, (draftState, action) => {
        const formulasPreview = action.payload;

        draftState.status = 'fulfilled';
        draftState.formulasPreviewSkincare = formulasPreview.formulasPreviewSkincare;
        draftState.formulasPreviewHaircare = formulasPreview.formulasPreviewHaircare;
        draftState.hairProfileUpdatedAt = formulasPreview.hairProfileUpdatedAt;
      })
      .addCase(fetchCustomerFormulasPreview.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      // Fetch coupons
      .addCase(fetchCustomerCoupons.pending, draftState => {
        draftState.status = 'pending';
        draftState.orders = [];
        draftState.error = null;
      })
      .addCase(fetchCustomerCoupons.fulfilled, (draftState, action) => {
        const { credits, promos, creditsPages, promosPages } = action.payload;

        const {
          credits: oldCredits,
          promos: oldPromos,
          creditsPages: oldCreditsPages,
          promosPages: oldPromosPages,
        } = draftState.coupons;

        draftState.status = 'fulfilled';
        draftState.coupons = {
          credits: credits ?? oldCredits,
          promos: promos ?? oldPromos,
          creditsPages: creditsPages ?? oldCreditsPages,
          promosPages: promosPages ?? oldPromosPages,
        };
      })
      .addCase(fetchCustomerCoupons.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      // Fetch subscriptions
      .addCase(fetchCustomerSubscriptions.pending, draftState => {
        draftState.status = 'pending';
        draftState.subscriptions = [];
        draftState.error = null;
      })
      .addCase(fetchCustomerSubscriptions.fulfilled, (draftState, action) => {
        const subscriptions = action.payload;

        draftState.status = 'fulfilled';
        draftState.subscriptions = subscriptions;
      })
      .addCase(fetchCustomerSubscriptions.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      // Fetch profiles
      .addCase(fetchCustomerProfiles.pending, draftState => {
        draftState.status = 'pending';
        draftState.customerProfiles = { profiles: [], hairProfilePubkey: '' };
        draftState.error = null;
      })
      .addCase(fetchCustomerProfiles.fulfilled, (draftState, action) => {
        const customerProfiles = action.payload;

        draftState.status = 'fulfilled';
        draftState.customerProfiles = customerProfiles;
      })
      .addCase(fetchCustomerProfiles.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      // Fetch question sets
      .addCase(fetchQuestionSets.pending, draftState => {
        draftState.status = 'pending';
        draftState.questionSet = null;
        draftState.error = null;
      })
      .addCase(fetchQuestionSets.fulfilled, (draftState, action) => {
        const questionSet = action.payload;

        draftState.status = 'fulfilled';
        draftState.questionSet = questionSet;
      })
      .addCase(fetchQuestionSets.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      // Fetch fragrances
      .addCase(fetchCustomerFragrances.pending, draftState => {
        draftState.status = 'pending';
        draftState.fragrances = [];
        draftState.error = null;
      })
      .addCase(fetchCustomerFragrances.fulfilled, (draftState, action) => {
        const fragrances = action.payload;

        draftState.status = 'fulfilled';
        draftState.fragrances = fragrances;
      })
      .addCase(fetchCustomerFragrances.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      // Fetch selfies
      .addCase(fetchCustomerSelfies.pending, draftState => {
        draftState.status = 'pending';
        draftState.customerProfiles = { profiles: [], hairProfilePubkey: '' };
        draftState.error = null;
      })
      .addCase(fetchCustomerSelfies.fulfilled, (draftState, action) => {
        const { imagesObjects, customerPubkey } = action.payload;

        draftState.status = 'fulfilled';
        draftState.imageDocs = { [customerPubkey]: imagesObjects };
      })
      .addCase(fetchCustomerSelfies.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      // Fetch error detection result
      .addCase(fetchErrorDetectionResult.pending, draftState => {
        draftState.status = 'pending';
        draftState.customerProfiles = { profiles: [], hairProfilePubkey: '' };
        draftState.error = null;
      })
      .addCase(fetchErrorDetectionResult.fulfilled, (draftState, action) => {
        const { errorDetectionResults, customerPubkey } = action.payload;

        draftState.status = 'fulfilled';
        draftState.errorDetectionResults = { [customerPubkey]: errorDetectionResults };
      })
      .addCase(fetchErrorDetectionResult.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      });
  },
});

const { reducer } = customerSlice;

export default reducer;
