import isBoolean from 'lodash/fp/isBoolean';
import isString from 'lodash/fp/isString';

import stringToBoolOrValue from 'utils/envParsing';
import getEnv from 'utils/getEnv';

import type { FeatureFlagState } from './types';

export const activeOrVariantStructureFromEnv = envVariable => {
  const value = stringToBoolOrValue(envVariable);
  if (isString(value)) {
    return { active: true, variant: value };
  }
  if (isBoolean(value)) {
    return { active: value };
  }
  return {};
};

export const initialState: FeatureFlagState = {
  status: 'idle',
  // Flags as we receive them from the back-end.
  flags: {
    'data-driven-production-flows-front': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_DATA_DRIVEN_PRODUCTION_FLOWS_FRONT')
    ),

    'survey-updates-phase-2': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_SURVEY_UPDATES_PHASE_2')
    ),

    'survey-updates-phase-1': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_SURVEY_UPDATES_PHASE_1')
    ),

    'microhold-palletization': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_MICROHOLD_PALLETIZATION')
    ),

    selfies: activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_SELFIES')),
    'selfies-error-detection': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_SELFIES_ERROR_DETECTION')
    ),
  },
};
